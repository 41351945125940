import React from 'react';
import * as Sentry from '@sentry/node';

import '../styles/global.css';

Sentry.init({
  dsn: 'https://6b5121b38d034010b3494c2d5f30cde9@o43145.ingest.sentry.io/5514195',
});

const App = ({ Component, pageProps }) => {
  return (
    <>
      <Component {...pageProps} />
    </>
  );
};

export default App;
